import React from "react"

import Home from "../components/Home/Home"
import SEO from "../components/Base/SEO"
import Layout from "../components/Base/Layout"

export default function index(props) {
  return (
    <Layout>
      <SEO
        title={"Market Research Reports & Consulting"}
        siteTitle={"Market Research Reports & Consulting"}
        description={"The business consulting firm Sheer Analytics And Insights offers action-ready market research reports, custom market analysis and consulting services."}
      />
      <Home />
    </Layout>
  )
}
